<template>
    <div class="modal" v-if="modelValue">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Refund A Payment</h1>
                    <button type="button" class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <Form @submit="handleSubmitForm" @invalid-submit="handleFormInvalidSubmit" class="refund_from" v-slot="{ errors }" ref="refund-form">
                        <p class="note mb-5">Please note that refunds take 5-10 days to appear on your customer's statement</p>
                        <div class="setting_wpr">
                            <div class="edit_section mb-5">
                                <label for="full_refund" class="switch_option capsule_btn">
                                    <h5>Full Refund <span>Refund the full {{ companyCurrencySymbol+refundAmount }}</span></h5>
                                    <input type="radio" value="full" id="full_refund" v-model="refundType" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="edit_section mb-5">
                                <label for="partial_refund" class="switch_option capsule_btn">
                                    <h5>Partial Refund <span>Refund part of the purchase</span></h5>
                                    <input type="radio" value="partial" id="partial_refund" v-model="refundType" hidden>
                                    <div><span></span></div>
                                </label>
                                <div class="form_grp" v-if="refundType === 'partial'">
                                    <div class="group_item">
                                        <label class="input_label">Refund Amount</label>
                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.refund_amount }">
                                            <Field type="number" class="no_spin" name="refund_amount" v-model="form.refund_amount" min="0" placeholder="0" label="refund amount" :rules="{ 'between': [1, refundAmount], 'required': true, }" />
                                            <span class="prefix"><i class="fas fa-dollar-sign"></i></span>
                                        </div>
                                        <ErrorMessage name="refund_amount" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-5">
                                <div class="group_item">
                                    <label class="input_label">Reason</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.reason }">
                                        <Field autocomplete="off" name="reason" v-model="form.reason" :class="{ 'has-error': errors.reason }" rules="required" label="refund reason">
                                            <multiselect
                                                v-model="form.reason"
                                                :options="refundReasons"
                                                value-prop="key"
                                                label="title"
                                                :searchable="true"
                                                placeholder="Select a reason"
                                            ></multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage  name="reason" class="text-danger" />
                                </div>
                            </div>
                            <div class="edit_section mb-5" v-if="form.reason">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Leave a note about this refund(Optional)</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.refund_note }">
                                            <Field type="text" v-model="form.refund_note" name="refund_note" placeholder="Refunded due to.." label="refund note" />
                                        </div>
                                        <ErrorMessage name="refund_note" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button id="return-payment-button"></button>
                    </Form>
                </div>
                <div class="modal_footer">
                    <button type="button" :disabled="refundPaymentLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button type="button" :disabled="refundPaymentLoader" class="btn save_btn" @click="handleSubmit"><i class="fa fa-spinner fa-spin" v-if="refundPaymentLoader"></i> {{ refundPaymentLoader ? 'Refunding' : 'Refund' }}</button>
                </div>
            </div>
        </div>
</template>

<script>
    import { Form, Field, ErrorMessage } from 'vee-validate'
    import { mapActions, mapState } from 'vuex';

    export default{
        name: 'Checkout Refund',

        data () {
            return {
                refundType: 'full',
                form: {
                    refund_amount: 0,
                    refund_note: '',
                    order_id: 0,
                    reason: '',
                    subscription_id: 0,
                },
                refundReasons: [
                    { title: 'Duplicate', key: 'duplicate' },
                    { title: 'Draudulent', key: 'fraudulent' },
                    { title: 'Requested by customer', key: 'requested_by_customer' },
                    { title: 'Other', key: 'other' },
                ],
            }
        },

        props: {
            modelValue: Boolean,
            refundAmount: {
                type: [ Number, String ],
                default: 0
            },
            orderId: {
                type: [ Number, String ],
                default: 0
            },
            subscriptionId: {
                type: [ Number, String ],
                default: 0
            },
            isSubscription: {
                type: Boolean,
                default: false
            },
            refreshRecords: {
                type: Function,
                default: () => {}
            }
        },

        emit: [ 'update:modelValue' ],

        components: {
            Form,
            Field,
            ErrorMessage,
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            refundPaymentLoader: state => state.checkoutModule.refundPaymentLoader,
            companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
        }),

        methods: {
            ...mapActions ({
                refundPayment: 'checkoutModule/refundPayment',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form  = {
                    refund_amount: 0,
                    refund_note: '',
                    order_id: vm.orderId,
                    reason: '',
                };

                vm.refundType = 'full';

                if (vm.isSubscription) {
                    vm.form.subscription_id = vm.subscriptionId;
                }
            },

            handleSubmit () {
                const vm = this;

                const submitButton = document.getElementById('return-payment-button');

                if (submitButton) {
                    submitButton.click();
                }
            },

            handleSubmitForm () {
                const vm = this;

                if (vm.refundType == 'full') {
                    vm.form.refund_amount = vm.refundAmount;
                }

                vm.refundPayment(vm.form).then((result) => {
                    if (result) {
                        vm.refreshRecords();
                        vm.closeModal();
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .form_grp input.no_spin::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    .form_grp input[type=number].no_spin {
        -moz-appearance: textfield;
    }
    .modal .refund_from{
        height: calc(100vh - 150px);
        display: flex;
        flex-direction: column;
    }
    p.note{
        padding: 15px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
    }
</style>
